import Vue, { VueConstructor, PluginObject } from 'vue'
import currency from 'currency.js'

const currencyPlugin: any = {}

export function format(value: string): string {
  if (!value) {
    return '$0.00'
  }

  return currency(value).format()
}

currencyPlugin.install = (vue: VueConstructor) => {
  vue.filter('currency', (value: string) => {
    return format(value)
  })

  vue.directive('currency', el => {
    el.innerHTML = format(el.innerHTML)
  })

  vue.prototype.$currency = currency
}

Vue.use(currencyPlugin)
