import { firestoreAction } from 'vuexfire'
import firebase from 'firebase/app'

import { BillsState, BillsContext } from '../types/store'
import {
  CrmCustomerProduct,
  CrmCustomerService,
  CrmCustomerBill,
  CrmUpdateObject,
  CrmQueryObject
} from '../types/crm-types'

export const state: () => BillsState = () => ({
  bills: [],
  baseRef: null,
  queryRef: null
})

export const actions = {
  bind: firestoreAction(
    async ({ state, bindFirestoreRef }: BillsContext, ref: firebase.firestore.CollectionReference | firebase.firestore.Query) => {
      if (ref instanceof firebase.firestore.CollectionReference) {
        state.baseRef = ref
      }

      if (ref instanceof firebase.firestore.Query) {
        state.queryRef = ref
      }

      return bindFirestoreRef('bills', ref)
    }
  ),

  async add({ state }: BillsContext, bill: CrmCustomerBill): Promise<void | firebase.firestore.DocumentReference> {
    if (state.baseRef) {
      return state.baseRef.add({
        ...bill
      })
    } else {
      return Promise.resolve()
    }
  },

  async remove({ state }: BillsContext, bill: CrmCustomerBill): Promise<void> {
    if (state.baseRef) {
      return state.baseRef.doc(bill.id).delete()
    } else {
      return Promise.resolve()
    }
  },

  async update({ state }: BillsContext, obj: CrmUpdateObject): Promise<void> {
    if (state.baseRef) {
      return state.baseRef.doc(obj.id).update({
        [obj.field]: obj.value
      })
    } else {
      return Promise.resolve()
    }
  },

  async updateAll(
    { state }: BillsContext,
    bill: CrmCustomerBill
  ): Promise<void> {
    if (state.baseRef !== null && state.baseRef) {
      return state.baseRef.doc(bill.id).set(bill)
    } else {
      return Promise.resolve()
    }
  },

  async query(
    { state, dispatch }: BillsContext,
    query: CrmQueryObject | CrmQueryObject[]
  ): Promise<firebase.firestore.DocumentData[] | void> {
    if (!state.baseRef) {
      return Promise.resolve()
    }

    if (Array.isArray(query)) {
      let queryRef = state.baseRef.where(query[0].fieldPath, query[0].op, query[0].value)

      for (let i = 1; i < query.length; i++) {
        queryRef = queryRef.where(query[i].fieldPath, query[i].op, query[i].value)
      }

      return dispatch('bind', queryRef.orderBy('date', 'desc'))
    } else {
      return dispatch('bind', state.baseRef.where(query.fieldPath, query.op, query.value))
    }
  },

  async reset({ state, dispatch }: BillsContext): Promise<firebase.firestore.DocumentData[] | void> {
    if (!state.baseRef) {
      return Promise.resolve()
    }

    return dispatch('bind', state.baseRef)
  }
}
