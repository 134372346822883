import { UserState } from '../types/store'
import firebase from 'firebase/app'

export const state: () => UserState = () => ({
  user: null,
  claims: {},
  users: []
})

export const mutations = {
  setUser(state: UserState, user: firebase.User): void {
    state.user = user
  },
  setUserMetadata(state: UserState, claims: { [key: string]: any }): void {
    state.claims = claims
  },
  setUsers(state: UserState, users: firebase.User[]): void {
    state.users = users
  }
}

