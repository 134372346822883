import { CrmBreadcrumb } from '~/types/crm-types'
import { StringMap } from '~/types/common'
import { Context } from '@nuxt/types'

export default ({ route, store }: Context) => {
  const iconMap: StringMap<string> = {
    reports: 'analytics',
    track: 'clock',
    admin: 'users-cog',
    settings: 'cog',
    users: 'users',
    products: 'apple-crate',
    services: 'concierge-bell',
    customers: 'building'
  }

  const elements: string[] = route.path.split('/').filter(item => item !== '')
  const crumbs: CrmBreadcrumb[] = [
    {
      label: 'Home',
      icon: 'home',
      link: '/'
    }
  ]

  const id = new RegExp('[a-zA-Z0-9]{20}')

  for (const path of elements) {
    if (!path.match(id)) {
      crumbs.push({
        label: path.charAt(0).toUpperCase() + path.slice(1),
        link: path,
        icon: iconMap[path]
      })
    }
  }

  crumbs
    .map((crumb, index) => {
      const path: string[] = []
      for (let i = index; i !== 0; i--) {
        path.unshift(crumbs[i].link)
      }
      crumb.link = `${path.join('/')}`
      return crumb
    })
    .map(crumb => {
      crumb.link = `/${crumb.link}`
      return crumb
    })

  store.commit('setBreadcrumbs', crumbs)
}
