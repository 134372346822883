import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _00cffd35 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _5ab48ca4 = () => interopDefault(import('../pages/admin/index/products.vue' /* webpackChunkName: "pages/admin/index/products" */))
const _a8ba28b0 = () => interopDefault(import('../pages/admin/index/services.vue' /* webpackChunkName: "pages/admin/index/services" */))
const _82023ba4 = () => interopDefault(import('../pages/admin/index/users.vue' /* webpackChunkName: "pages/admin/index/users" */))
const _2208992b = () => interopDefault(import('../pages/calendar.vue' /* webpackChunkName: "pages/calendar" */))
const _c98f9f8c = () => interopDefault(import('../pages/landing.vue' /* webpackChunkName: "pages/landing" */))
const _efa73268 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _2b6e6e36 = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _da1c0bda = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _25052b4e = () => interopDefault(import('../pages/settings/index/customers.vue' /* webpackChunkName: "pages/settings/index/customers" */))
const _2984bf9e = () => interopDefault(import('../pages/track/index.vue' /* webpackChunkName: "pages/track/index" */))
const _1ff63df6 = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _7350df96 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _00cffd35,
    name: "admin",
    children: [{
      path: "products",
      component: _5ab48ca4,
      name: "admin-index-products"
    }, {
      path: "services",
      component: _a8ba28b0,
      name: "admin-index-services"
    }, {
      path: "users",
      component: _82023ba4,
      name: "admin-index-users"
    }]
  }, {
    path: "/calendar",
    component: _2208992b,
    name: "calendar"
  }, {
    path: "/landing",
    component: _c98f9f8c,
    name: "landing"
  }, {
    path: "/profile",
    component: _efa73268,
    name: "profile"
  }, {
    path: "/reports",
    component: _2b6e6e36,
    name: "reports"
  }, {
    path: "/settings",
    component: _da1c0bda,
    name: "settings",
    children: [{
      path: "customers",
      component: _25052b4e,
      name: "settings-index-customers"
    }]
  }, {
    path: "/track",
    component: _2984bf9e,
    name: "track"
  }, {
    path: "/customer/:id?",
    component: _1ff63df6,
    name: "customer-id"
  }, {
    path: "/",
    component: _7350df96,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
