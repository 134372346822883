import { firestoreAction } from 'vuexfire'
import firebase from 'firebase/app'

import { SingleCustomerState, SingleCustomerContext, SingleCustomerBind } from '../types/store'
import {
  CrmCustomer,
  CrmCustomerProduct,
  CrmCustomerService,
  CrmUpdateObject
} from '../types/crm-types'

export const state: () => SingleCustomerState = () => ({
  customer: {},
  customerProducts: [],
  customerServices: [],
  refs: null
})

export const mutations = {
  setCustomer(state: SingleCustomerState, customer: CrmCustomer): void {
    state.customer = customer
  },
}

export const actions = {
  bindCustomer: firestoreAction(
    async (
      { state, bindFirestoreRef }: SingleCustomerContext,
      bindObj: SingleCustomerBind
    ) => {
      state.refs = bindObj

      if (state.refs.customerRef) {
        await bindFirestoreRef('customer', state.refs.customerRef)
      }

      if (state.refs.productsRef) {
        await bindFirestoreRef('customerProducts', state.refs.productsRef)
      }

      if (state.refs.servicesRef) {
        await bindFirestoreRef('customerServices', state.refs.servicesRef)
      }
    }
  ),

  unbindCustomer: firestoreAction(
    async ({ state, unbindFirestoreRef }: SingleCustomerContext) => {
      unbindFirestoreRef('customer')
      unbindFirestoreRef('customerProducts')
      unbindFirestoreRef('customerServices')

      state.customer = {}
      state.customerProducts = []
      state.customerServices = []
    }
  ),

  async updateCustomer(
    { state }: SingleCustomerContext,
    obj: CrmUpdateObject
  ): Promise<void> {
    if (state.refs) {
      return state.refs.customerRef.update({
        [obj.field]: obj.value
      })
    } else {
      return Promise.resolve()
    }
  },

  async updateCustomerAll(
    { state }: SingleCustomerContext,
    customer: CrmCustomer
  ): Promise<void> {
    if (state.refs) {
      return state.refs.customerRef.update(customer)
    } else {
      return Promise.resolve()
    }
  },

  // CUSTOMER PRODUCTS

  async addCustomerProduct(
    { state }: SingleCustomerContext,
    customerProduct: CrmCustomerProduct
  ): Promise<void | firebase.firestore.DocumentReference> {
    if (state.refs !== null && state.refs.productsRef) {
      return state.refs.productsRef.add({
        ...customerProduct
      })
    } else {
      return Promise.resolve()
    }
  },

  async updateCustomerProduct(
    { state }: SingleCustomerContext,
    obj: CrmUpdateObject
  ): Promise<void> {
    if (state.refs !== null && state.refs.productsRef) {
      return state.refs.productsRef.doc(obj.id).update({
        [obj.field]: obj.value
      })
    } else {
      return Promise.resolve()
    }
  },

  async updateCustomerProductAll(
    { state }: SingleCustomerContext,
    product: CrmCustomerProduct
  ): Promise<void> {
    if (state.refs !== null && state.refs.productsRef) {
      return state.refs.productsRef.doc(product.id).set(product)
    } else {
      return Promise.resolve()
    }
  },

  async removeCustomerProduct(
    { state }: SingleCustomerContext,
    customerProduct: CrmCustomerProduct
  ): Promise<void> {
    if (state.refs !== null && state.refs.productsRef) {
      return state.refs.productsRef.doc(customerProduct.id).delete()
    } else {
      return Promise.resolve()
    }
  },

  // CUSTOMER SERVICES

  async addCustomerService(
    { state }: SingleCustomerContext,
    customerService: CrmCustomerService
  ): Promise<void | firebase.firestore.DocumentReference> {
    if (state.refs !== null && state.refs.servicesRef) {
      return state.refs.servicesRef.add({
        ...customerService
      })
    } else {
      return Promise.resolve()
    }
  },

  async updateCustomerService(
    { state }: SingleCustomerContext,
    obj: CrmUpdateObject
  ): Promise<void> {
    if (state.refs !== null && state.refs.servicesRef) {
      return state.refs.servicesRef.doc(obj.id).update({
        [obj.field]: obj.value
      })
    } else {
      return Promise.resolve()
    }
  },

  async updateCustomerServiceAll(
    { state }: SingleCustomerContext,
    service: CrmCustomerService
  ): Promise<void> {
    if (state.refs !== null && state.refs.servicesRef) {
      return state.refs.servicesRef.doc(service.id).set(service)
    } else {
      return Promise.resolve()
    }
  },

  async removeCustomerService(
    { state }: SingleCustomerContext,
    customerService: CrmCustomerService
  ): Promise<void> {
    if (state.refs !== null && state.refs.servicesRef) {
      return state.refs.servicesRef.doc(customerService.id).delete()
    } else {
      return Promise.resolve()
    }
  }
}
