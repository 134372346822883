import { RootState, AuthState } from '~/types/store'
import { Store } from 'vuex'
import { CrmBreadcrumb } from '~/types/crm-types'

import firebase from 'firebase/app'
import 'firebase/firestore'

import { vuexfireMutations } from 'vuexfire'

import UserService from '@/services/user-service'

const rootState: RootState = {
  breadcrumbs: [],
  userList: []
}

export const strict = false
export const state = () => rootState

export const mutations = {
  ...vuexfireMutations,
  setBreadcrumbs(state: RootState, breadcrumbs: CrmBreadcrumb[]) {
    state.breadcrumbs = breadcrumbs
  },
  setUserList(state: RootState, users: firebase.User[]) {
    state.userList = users
  }
}

export const actions = {
  // async nuxtServerInit({ commit }: Store<any>, { req, redirect }: Context): Promise<any> {
  //   const request = req as any

  //   if (request.user && typeof request.user !== 'undefined') {
  //     commit('user/setUserMetadata', request.setUserMetadata)
  //     commit('user/setUser', request.user)
  //   } else {
  //     commit('user/setUserMetadata', null)
  //     commit('user/setUser', null)

  //     redirect('/landing')
  //   }
  // },
  authStateChanged(store: Store<any>, state: AuthState): void {
    if (!state.authUser) {
      store.state.user.user = null
      store.state.user.claims = {}
    } else {
      store.state.user.user = state.authUser
      store.state.user.claims = state.claims
    }
  },
  async loadUsers(
    { commit }: Store<any>,
    user: firebase.User
  ): Promise<firebase.User[]> {
    const token = await user.getIdToken(true)
    const users = await UserService.getUsers(token)

    commit('setUserList', users)

    return users
  }
}
