import { firestoreAction } from 'vuexfire'
import firebase from 'firebase/app'

import { CrmProduct, CrmUpdateObject } from '../types/crm-types'
import { ProductsState, ProductsContext } from '../types/store'

export const state: () => ProductsState = () => ({ products: [], baseRef: null, queryRef: null })

export const actions = {
  bind: firestoreAction(
    async ({ state, bindFirestoreRef }: ProductsContext, ref: firebase.firestore.CollectionReference | firebase.firestore.Query) => {
      if (ref instanceof firebase.firestore.CollectionReference) {
        state.baseRef = ref
      }

      if (ref instanceof firebase.firestore.Query) {
        state.queryRef = ref
      }

      return bindFirestoreRef('products', ref)
    }
  ),

  async add({ state }: ProductsContext, product: CrmProduct): Promise<void | firebase.firestore.DocumentReference> {
    if (state.baseRef) {
      return state.baseRef.add({
        ...product
      })
    } else {
      return Promise.resolve()
    }
  },

  async remove({ state }: ProductsContext, product: CrmProduct): Promise<void> {
    if (state.baseRef) {
      return state.baseRef.doc(product.id).delete()
    } else {
      return Promise.resolve()
    }
  },

  async update({ state }: ProductsContext, obj: CrmUpdateObject): Promise<void> {
    if (state.baseRef) {
      return state.baseRef.doc(obj.id).update({
        [obj.field]: obj.value
      })
    } else {
      return Promise.resolve()
    }
  }
}
