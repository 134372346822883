import axios from 'axios'
import firebase from 'firebase/app'

export interface UserRequest {
  userId?: string
  name: string
  email: string
  userClaims?: { [key: string]: any }
  phoneNumber?: string | null
  photoUrl?: string | null
  password?: string | null
  token: string
}

class UserService {
  private baseUrl: string = `${process.env.serviceUrl}/user`

  async getUsers(token: string): Promise<firebase.User[]> {
    try {
      const usersResponse = await axios.get(`${this.baseUrl}/list`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      return usersResponse.data
    } catch (error) {
      console.error(error)
      return []
    }
  }

  async newUser(userRequest: UserRequest) {
    const claims = userRequest.userClaims

    const request: any = {
      name: userRequest.name,
      email: userRequest.email
    }

    if (claims && claims.length > 0) {
      request.claims = claims
    }

    if (userRequest.phoneNumber) {
      request.phoneNumber = userRequest.phoneNumber
    }

    if (userRequest.photoUrl) {
      request.photoUrl = userRequest.photoUrl
    }

    try {
      const usersResponse = await axios.post(`${this.baseUrl}/new`, request, {
        headers: { Authorization: `Bearer ${userRequest.token}` }
      })

      return usersResponse.data
    } catch (error) {
      console.error(error)
    }
  }

  async editUser(userRequest: UserRequest) {
    const request: any = {
      name: userRequest.name,
      email: userRequest.email
    }

    if (userRequest.userClaims) {
      request.claims = userRequest.userClaims
    }

    if (userRequest.phoneNumber) {
      request.phoneNumber = userRequest.phoneNumber
    }

    if (userRequest.photoUrl) {
      request.photoUrl = userRequest.photoUrl
    }

    if (userRequest.password) {
      request.password = userRequest.password
    }

    try {
      const usersResponse = await axios.put(
        `${this.baseUrl}/${userRequest.userId}`,
        request,
        {
          headers: {
            Authorization: `Bearer ${userRequest.token}`
          }
        }
      )

      return usersResponse.data
    } catch (error) {
      console.error(error)
    }
  }

  async deleteUser(userId: string, token: string) {
    try {
      const deleteResponse = await axios.delete(`${this.baseUrl}/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return deleteResponse
    } catch (error) {
      console.error(error)
    }
  }
}

export default new UserService()
