




import { Component, Prop, Watch, Vue } from 'nuxt-property-decorator'

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'

@Component({
  components: {
    FullCalendar
  }
})
export default class AirliftCalendar extends Vue {
  @Prop({ required: true }) events!: any
  @Prop({ required: true }) weekends!: boolean
  @Prop({ required: true }) eventLimit!: boolean
  @Prop({ required: true }) columnHeaderFormat!: any
  @Prop({ required: true }) header!: any
  @Prop({ required: true }) buttonText!: any

  calendarOptions = {
    plugins: [dayGridPlugin, listPlugin],
    initialView: 'dayGridMonth',
    events: this.events,
    weekends: this.weekends,
    dayMaxEventRows: this.eventLimit,
    dayHeaderFormat: this.columnHeaderFormat,
    headerToolbar: this.header,
    buttonText: this.buttonText,
    eventClick: this.handleEventClick
  }

  @Watch('events', { immediate: true, deep: true })
  handleEventsChanged(value: any) {
    console.log(value)
    this.calendarOptions.events = value
  }

  handleEventClick(eventInfo: any) {
    this.$emit('event-click', eventInfo)
  }
}
