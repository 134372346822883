import Vue from 'vue'

const vueE164: any = {}
export function filter(value: string) {
  if (!value) return ''

  return value.replace(/\+1([0-9]{3})([0-9]{3})([0-9]{4})/g, '($1) $2-$3')
}

vueE164.install = (vue: any) => {
  vue.filter('phone', (value: string) => {
    return filter(value)
  })

  vue.directive('phone', (el: HTMLElement) => {
    el.innerHTML = filter(el.innerHTML)
  })

  vue.prototype.$filterPhone = filter
}

Vue.use(vueE164)
