import { Context } from '@nuxt/types'
import * as Sentry from '@sentry/browser'

interface RouteClaim {
  path: string
  claims: string[]
}

export default ({ app, store, redirect, route }: Context) => {
  const sentry = app.$sentry as typeof Sentry

  const requiredClaims: RouteClaim[] = [
    {
      path: '/profile',
      claims: ['all']
    },
    {
      path: '/landing',
      claims: ['all']
    },
    {
      path: '/admin',
      claims: ['admin']
    },
    {
      path: '/settings',
      claims: ['admin', 'superuser']
    },
    {
      path: '/track',
      claims: ['admin', 'superuser', 'sales', 'tracker', 'readonly', 'all']
    },
    {
      path: '/sheet',
      claims: ['admin', 'superuser', 'sales', 'readonly']
    },
    {
      path: '/calendar',
      claims: ['admin', 'superuser', 'sales', 'readonly']
    },
    {
      path: '/reports',
      claims: ['admin', 'superuser', 'sales', 'readonly', 'reports']
    },
    {
      path: '/customer',
      claims: ['admin', 'superuser', 'sales', 'readonly']
    }
  ]

  sentry.setUser(null)

  if (!store.state.user.user || typeof store.state.user.user.email === 'undefined') {
    store.commit('user/setUserMetadata', null)
    store.commit('user/setUser', null)

    redirect('/landing')
    return
  }

  sentry.setUser({
    id: store.state.user.claims.user_id,
    email: store.state.user.claims.email,
    username: store.state.user.claims.name
  })

  if (route.path === '/') {
    if (store.state.user.claims.tracker) {
      redirect('/track')
    }
    return
  }

  const currentRoute = requiredClaims.filter(routeClaim => route.path.startsWith(routeClaim.path))

  if (currentRoute.length === 0) {
    redirect('/')
    return
  }

  const routeClaims = currentRoute[0].claims

  if (routeClaims.indexOf('all') === -1) {
    const intersect = Object.keys(store.state.user.claims).filter(claim => routeClaims.includes(claim))

    if (intersect.length === 0) {
      redirect('/')
    }
  }
}
